import React from "react"

const Ptblink = () => (
    <svg width="125" height="34" viewBox="0 0 125 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M78.0543 4.60867C79.327 4.60867 80.3587 3.57698 80.3587 2.30433C80.3587 1.03168 79.327 0 78.0543 0C76.7817 0 75.75 1.03168 75.75 2.30433C75.75 3.57698 76.7817 4.60867 78.0543 4.60867Z" fill="white"/>
    <path d="M104.844 0.00183105H109.248V8.5618C109.248 8.96232 109.089 9.34644 108.806 9.62965C108.523 9.91286 108.138 10.072 107.738 10.072H104.844V0.00183105Z" fill="white"/>
    <path d="M7.54194 6.29468L0 13.8366V21.3981H4.40435L0 25.8096V34H4.40435V25.8096H12.5504L19.5149 18.845V10.978C19.5149 9.73742 19.0227 8.54755 18.1463 7.66952C17.27 6.79149 16.081 6.29703 14.8405 6.29468H7.54194ZM15.1105 21.4052H4.4079V12.2092C4.4079 11.8087 4.56701 11.4246 4.85022 11.1413C5.13343 10.8581 5.51755 10.699 5.91807 10.699H13.5968C13.7953 10.6988 13.9918 10.7377 14.1753 10.8135C14.3587 10.8893 14.5254 11.0005 14.6658 11.1407C14.8062 11.281 14.9176 11.4475 14.9936 11.6309C15.0696 11.8142 15.1088 12.0107 15.1088 12.2092L15.1105 21.4052Z" fill="white"/>
    <path d="M124.17 25.8096L113.35 14.9897C113.068 14.7067 112.909 14.3233 112.909 13.9237C112.909 13.5241 113.068 13.1408 113.35 12.8577L119.909 6.29828H113.691L105.724 14.2613C105.157 14.8262 104.838 15.593 104.836 16.3933V25.8096H109.242V17.104L117.948 25.8096H124.17Z" fill="white"/>
    <path d="M88.7263 6.29468C87.4842 6.29468 86.293 6.78809 85.4147 7.66638C84.5364 8.54467 84.043 9.73588 84.043 10.978V25.8096H88.4527V12.2092C88.4524 12.0108 88.4913 11.8143 88.5671 11.631C88.6429 11.4477 88.7542 11.2811 88.8944 11.1408C89.0347 11.0005 89.2013 10.8893 89.3846 10.8135C89.5679 10.7377 89.7644 10.6988 89.9628 10.699H96.6271V25.8096H101.037V13.2663L94.0793 6.29468H88.7263Z" fill="white"/>
    <path d="M52.2833 6.2947H44.7343V0.00708008H40.3281V8.28456V18.8806L47.2571 25.8096H55.1722C55.7872 25.8096 56.3962 25.6884 56.9644 25.4531C57.5326 25.2177 58.0489 24.8728 58.4837 24.4379C58.9186 24.003 59.2636 23.4867 59.4989 22.9185C59.7343 22.3503 59.8554 21.7413 59.8554 21.1263V13.8615L52.2833 6.2947ZM55.444 12.2092V21.4052H44.7414V12.2092C44.7414 11.8087 44.9005 11.4246 45.1837 11.1414C45.4669 10.8582 45.851 10.699 46.2515 10.699H53.9285C54.1273 10.6983 54.3242 10.7369 54.508 10.8125C54.6919 10.888 54.859 10.9992 54.9998 11.1395C55.1406 11.2798 55.2523 11.4465 55.3285 11.6301C55.4047 11.8136 55.444 12.0104 55.444 12.2092Z" fill="white"/>
    <path d="M27.7442 19.8951V10.7079H37.8481L33.442 6.3036H27.7442V0.00177002H23.3398V18.8806L30.2688 25.8096H34.6607V21.4052H29.2544C28.854 21.4048 28.4701 21.2455 28.187 20.9624C27.9039 20.6793 27.7447 20.2955 27.7442 19.8951Z" fill="white"/>
    <path d="M68.8284 21.4052C68.6762 21.4052 68.5256 21.3753 68.3851 21.3171C68.2445 21.2588 68.1168 21.1735 68.0093 21.066C67.9017 20.9584 67.8164 20.8307 67.7582 20.6901C67.6999 20.5496 67.67 20.399 67.67 20.2469V0.00354004H63.2656V18.9232L70.152 25.8096H72.0726V21.4052H68.8284Z" fill="white"/>
    <path d="M74.6016 10.6955C74.9349 10.6955 75.2545 10.8278 75.4904 11.0633C75.7262 11.2988 75.859 11.6183 75.8594 11.9516V25.8096H80.2247V6.33734L74.6016 10.6955Z" fill="white"/>
    </svg>
  )

export default Ptblink