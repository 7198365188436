import React from "react"

const Waddle = () => (
  <svg
    role="img"
    aria-labelledby="waddle-logo-img"
    width="221"
    height="67"
    viewBox="0 0 221 67"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="waddle-logo-img">Waddle logo</title>
    <path d="M68.063 66.4446V55.7446H75.463V57.9446H70.763V60.3446H74.963V62.5446H70.763V66.3446H68.063V66.4446Z" fill="#FFF"/>
    <path d="M83.563 66.4446C83.263 66.1446 83.163 65.8446 83.063 65.2446L82.763 63.8446C82.563 62.9446 82.063 62.6446 81.263 62.6446H79.663V66.3446H77.063V55.7446H81.863C84.363 55.7446 85.863 56.7446 85.863 58.9446C85.863 60.5446 84.763 61.4446 83.563 61.6446C84.563 61.8446 85.063 62.3446 85.363 63.3446L85.763 65.1446C85.863 65.6446 86.063 66.0446 86.463 66.4446H83.563ZM81.663 57.9446H79.763V60.5446H81.663C82.663 60.5446 83.163 60.1446 83.163 59.1446C83.163 58.4446 82.663 57.9446 81.663 57.9446Z" fill="#FFF"/>
    <path d="M97.3631 61.1445C97.3631 64.5445 95.3631 66.6445 92.2631 66.6445C89.1631 66.6445 87.1631 64.5445 87.1631 61.1445C87.1631 57.7445 89.1631 55.6445 92.2631 55.6445C95.3631 55.6445 97.3631 57.7445 97.3631 61.1445ZM89.9631 61.1445C89.9631 63.1445 90.7631 64.4445 92.2631 64.4445C93.7631 64.4445 94.5631 63.1445 94.5631 61.1445C94.5631 59.1445 93.7631 57.8445 92.2631 57.8445C90.7631 57.8445 89.9631 59.1445 89.9631 61.1445Z" fill="#FFF"/>
    <path d="M105.063 64.0446H103.463L101.463 59.8446V62.0446V66.5446H98.8628V55.7446H101.763L104.263 60.9446L106.763 55.7446H109.563V66.4446H106.963V61.9446V59.7446L105.063 64.0446Z" fill="#FFF"/>
    <path d="M119.563 63.0445L117.363 66.4445H114.463L118.063 61.0445L114.663 55.8445H117.663L119.663 59.0445L121.663 55.8445H124.563L121.163 61.0445L124.763 66.5445H121.763L119.563 63.0445Z" fill="#FFF"/>
    <path d="M133.463 66.4446H125.863V55.7446H133.363V57.9446H128.463V59.9446H132.863V61.9446H128.463V64.2446H133.463V66.4446Z" fill="#FFF"/>
    <path d="M141.763 66.4446C141.463 66.1446 141.363 65.8446 141.263 65.2446L140.963 63.8446C140.763 62.9446 140.263 62.6446 139.463 62.6446H137.863V66.3446H135.263V55.7446H140.063C142.563 55.7446 144.063 56.7446 144.063 58.9446C144.063 60.5446 142.963 61.4446 141.763 61.6446C142.763 61.8446 143.263 62.3446 143.563 63.3446L143.963 65.1446C144.063 65.6446 144.263 66.0446 144.663 66.4446H141.763ZM139.863 57.9446H137.963V60.5446H139.863C140.863 60.5446 141.363 60.1446 141.363 59.1446C141.263 58.4446 140.863 57.9446 139.863 57.9446Z" fill="#FFF"/>
    <path d="M155.563 61.1445C155.563 64.5445 153.563 66.6445 150.463 66.6445C147.363 66.6445 145.363 64.5445 145.363 61.1445C145.363 57.7445 147.363 55.6445 150.463 55.6445C153.563 55.6445 155.563 57.7445 155.563 61.1445ZM148.163 61.1445C148.163 63.1445 148.963 64.4445 150.463 64.4445C151.963 64.4445 152.763 63.1445 152.763 61.1445C152.763 59.1445 151.963 57.8445 150.463 57.8445C148.963 57.8445 148.163 59.1445 148.163 61.1445Z" fill="#FFF"/>
    <path d="M48.1627 23.7447C48.1627 24.9447 47.1627 25.9447 45.9627 25.9447C44.7627 25.9447 43.7627 24.9447 43.7627 23.7447C43.7627 22.5447 44.7627 21.5447 45.9627 21.5447C47.1627 21.4447 48.1627 22.4447 48.1627 23.7447Z" fill="#FFF"/>
    <path d="M66.6626 26.0448L74.2626 45.2448L80.3626 26.1448H83.1626L89.3626 45.2448L96.9626 25.9448H99.7626L90.5626 48.4448H88.1626L81.9626 29.4448L75.3626 48.4448H72.9626L64.1626 25.9448H66.6626V26.0448Z" fill="#FFF"/>
    <path d="M126.463 48.4447H124.362V43.8447C123.962 44.3447 123.562 44.8447 123.062 45.3447C120.562 47.8447 117.662 49.0447 114.162 49.0447C110.662 49.0447 107.762 47.8447 105.262 45.3447C102.762 42.8447 101.562 39.9447 101.562 36.4447C101.562 32.9447 102.762 29.9447 105.262 27.5447C107.762 25.0447 110.662 23.8447 114.162 23.8447C117.662 23.8447 120.662 25.0447 123.062 27.5447C123.562 28.0447 123.962 28.5447 124.362 29.0447V25.8447H126.463V48.4447ZM124.362 38.5447V34.4447C123.962 32.4447 123.063 30.6447 121.463 29.1447C119.463 27.1447 116.962 26.0447 114.062 26.0447C111.162 26.0447 108.762 27.0447 106.662 29.1447C104.662 31.1447 103.662 33.6447 103.662 36.5447C103.662 39.4447 104.662 41.8447 106.662 43.8447C108.662 45.8447 111.162 46.8447 114.062 46.8447C116.962 46.8447 119.363 45.8447 121.463 43.7447C123.063 42.3447 123.962 40.6447 124.362 38.5447Z" fill="#FFF"/>
    <path d="M155.563 48.4447H153.463V43.8447C153.063 44.3447 152.663 44.8447 152.163 45.3447C149.663 47.8447 146.763 49.0447 143.263 49.0447C139.763 49.0447 136.863 47.8447 134.363 45.3447C131.863 42.8447 130.663 39.9447 130.663 36.4447C130.663 32.9447 131.863 29.9447 134.363 27.5447C136.863 25.0447 139.763 23.8447 143.263 23.8447C146.763 23.8447 149.763 25.0447 152.163 27.5447C152.663 28.0447 153.063 28.5447 153.463 29.0447V11.8447H155.563V48.4447ZM153.463 38.5447V34.4447C153.063 32.4447 152.163 30.6447 150.563 29.1447C148.563 27.1447 146.063 26.0447 143.163 26.0447C140.263 26.0447 137.863 27.0447 135.863 29.1447C133.863 31.1447 132.863 33.6447 132.863 36.5447C132.863 39.4447 133.863 41.8447 135.863 43.8447C137.863 45.8447 140.363 46.8447 143.163 46.8447C146.063 46.8447 148.463 45.8447 150.563 43.7447C152.163 42.3447 153.063 40.6447 153.463 38.5447Z" fill="#FFF"/>
    <path d="M184.663 48.4447H182.563V43.8447C182.163 44.3447 181.763 44.8447 181.263 45.3447C178.763 47.8447 175.863 49.0447 172.363 49.0447C168.863 49.0447 165.963 47.8447 163.463 45.3447C160.963 42.8447 159.763 39.9447 159.763 36.4447C159.763 32.9447 160.963 29.9447 163.463 27.5447C165.963 25.0447 168.863 23.8447 172.363 23.8447C175.863 23.8447 178.863 25.0447 181.263 27.5447C181.763 28.0447 182.163 28.5447 182.563 29.0447V11.8447H184.663V48.4447ZM182.563 38.5447V34.4447C182.163 32.4447 181.263 30.6447 179.663 29.1447C177.663 27.1447 175.163 26.0447 172.263 26.0447C169.363 26.0447 166.963 27.0447 164.963 29.1447C162.963 31.1447 161.963 33.6447 161.963 36.5447C161.963 39.4447 162.963 41.8447 164.963 43.8447C166.963 45.8447 169.463 46.8447 172.263 46.8447C175.163 46.8447 177.563 45.8447 179.663 43.7447C181.263 42.3447 182.163 40.6447 182.563 38.5447Z" fill="#FFF"/>
    <path d="M191.863 11.8447V48.4447H189.763V11.8447H191.863Z" fill="#FFF"/>
    <path d="M198.262 35.4448C198.162 35.8448 198.162 36.2448 198.162 36.6448C198.162 39.5448 199.162 41.9448 201.162 43.9448C203.162 45.9448 205.662 46.9448 208.462 46.9448C211.362 46.9448 213.762 45.9448 215.862 43.8448C216.162 43.5448 216.562 43.1448 216.862 42.7448L219.062 43.5448C218.762 43.9448 218.262 44.6448 217.462 45.4448C214.962 47.9448 212.062 49.1448 208.562 49.1448C205.062 49.1448 202.162 47.9448 199.662 45.4448C197.162 42.9448 195.962 40.0448 195.962 36.5448C195.962 33.0448 197.162 30.0448 199.662 27.6448C202.162 25.1448 205.062 23.9448 208.562 23.9448C212.062 23.9448 215.062 25.1448 217.462 27.6448C219.662 29.8448 220.662 32.4448 220.662 35.4448H198.262V35.4448ZM198.662 33.3448H218.462C217.962 31.7448 217.162 30.4448 215.962 29.2448C213.962 27.2448 211.462 26.1448 208.562 26.1448C205.662 26.1448 203.262 27.1448 201.162 29.2448C200.062 30.4448 199.162 31.8448 198.662 33.3448Z" fill="#FFF"/>
    <path d="M38.5628 43.7446C38.5628 43.3446 38.3628 42.8446 38.0628 42.6446C36.3628 41.1446 32.9628 41.2446 30.2628 40.7446C27.3628 40.1446 24.5628 39.5446 21.9628 38.2446C18.7628 36.6446 12.4628 32.5446 13.6628 27.4446C15.0628 21.7446 24.6628 27.1446 29.8628 25.3446C32.6628 24.3446 33.7628 21.7446 35.2628 19.9446C38.2628 16.4446 43.2628 12.9446 51.0628 14.1446C51.7628 14.2446 52.4628 14.4446 53.1628 14.6446C53.8628 14.8446 54.4628 14.1446 54.0628 13.5446C48.4628 4.94462 38.6628 -0.555374 27.6628 0.0446258C12.9628 0.844626 0.962775 12.6446 0.0627752 27.3446C-1.03722 44.3446 12.4628 58.4446 29.2628 58.4446C29.8628 58.4446 30.4628 58.4446 30.9628 58.3446C31.4628 58.3446 31.9628 58.0446 32.2628 57.6446C35.3628 53.9446 39.0628 50.0446 38.5628 43.7446Z" fill="#FFF"/>
  </svg>
  )

export default Waddle