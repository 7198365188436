import React from "react"

const Apos = () => (
  <svg width="138" height="32" viewBox="0 0 138 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Apositive Logo</title>
<path d="M22.4461 1.31428C22.4461 1.31428 22.4119 1.27999 22.3661 1.27999C22.149 1.09714 21.8176 1.02856 21.4861 1.02856H15.4176C14.389 1.02856 13.6919 1.75999 13.3947 2.22856L1.47472 21.4286C1.32614 21.6457 1.25757 21.8629 1.189 22.1257C0.960431 23.1429 1.65757 24.0229 2.69757 24.0229C3.57757 24.0229 4.309 23.44 4.75472 22.7428L7.80615 17.9086L15.6004 4.46856H19.829V8.30856C20.2747 7.98856 20.8576 7.75999 21.4519 7.75999C22.0347 7.75999 22.5947 7.94285 23.0633 8.30856V2.59428C23.109 2.07999 22.8461 1.61142 22.4461 1.31428Z" fill="white"/>
<path d="M23.1317 22.457C23.1317 23.337 22.4003 24.0684 21.5203 24.0684C20.6289 24.0684 19.8975 23.337 19.8975 22.457V18.057H15.4517C14.606 18.057 13.8975 17.3713 13.8975 16.5256C13.8975 15.6799 14.606 14.9827 15.4517 14.9827H19.8975V10.5484C19.8975 9.66844 20.6289 8.93701 21.5203 8.93701C22.4003 8.93701 23.1317 9.66844 23.1317 10.5484V22.457Z" fill="white"/>
<path d="M23.1318 14.9829H27.589C28.4347 14.9829 29.1318 15.6801 29.1318 16.5258C29.1318 17.3715 28.4347 18.0572 27.589 18.0572H23.1318V14.9829Z" fill="#FF9B00"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.1035 30.9714V7.75994H33.2235L33.4178 10.3314C33.9968 9.40947 34.7968 8.69708 35.8178 8.19423C36.8311 7.69137 37.8978 7.43994 39.0178 7.43994C41.3721 7.4628 43.2959 8.24375 44.7892 9.7828C46.2749 11.3295 47.0178 13.3028 47.0178 15.7028C47.0178 18.1257 46.2902 20.1104 44.8349 21.6571C43.3797 23.1961 41.4407 23.9657 39.0178 23.9657C37.9207 23.9657 36.854 23.7447 35.8178 23.3028C34.774 22.8685 33.974 22.2018 33.4178 21.3028V30.9714H30.1035ZM42.2864 19.3371C43.2311 18.3923 43.7035 17.1809 43.7035 15.7028C43.7035 14.2018 43.2426 12.9866 42.3207 12.0571C41.3988 11.1199 40.1873 10.6514 38.6864 10.6514C37.1854 10.6514 35.9816 11.1352 35.0749 12.1028C34.1607 13.0628 33.7035 14.2628 33.7035 15.7028C33.7035 17.1352 34.1759 18.3352 35.1207 19.3028C36.0654 20.2704 37.254 20.7542 38.6864 20.7542C40.1492 20.7542 41.3492 20.2818 42.2864 19.3371Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M62.3773 9.80566C63.9087 11.3752 64.6744 13.3409 64.6744 15.7028C64.6744 18.0799 63.9201 20.0457 62.4116 21.5999C60.8954 23.1542 58.9068 23.9314 56.4458 23.9314C54.0001 23.9314 52.0268 23.1542 50.5258 21.5999C49.0249 20.0457 48.2744 18.0799 48.2744 15.7028C48.2744 13.3257 49.0287 11.3523 50.5373 9.7828C52.0535 8.22089 54.023 7.43994 56.4458 7.43994C58.8687 7.43994 60.8458 8.22851 62.3773 9.80566ZM52.9258 11.9999C52.0344 13.0133 51.5887 14.2476 51.5887 15.7028C51.5887 17.158 52.0268 18.3809 52.903 19.3714C53.7868 20.3542 54.9677 20.8457 56.4458 20.8457C57.9239 20.8457 59.1087 20.3542 60.0001 19.3714C60.8839 18.3809 61.3258 17.158 61.3258 15.7028C61.3258 14.2628 60.8649 13.0361 59.943 12.0228C59.0211 11.0018 57.8554 10.4914 56.4458 10.4914C54.983 10.4914 53.8097 10.9942 52.9258 11.9999Z" fill="white"/>
<path d="M77.0293 11.6457C75.9551 10.6629 74.5608 10.1714 72.8465 10.1714C71.8179 10.1486 70.9989 10.3048 70.3893 10.64C69.7722 10.9676 69.4636 11.4438 69.4636 12.0686C69.4865 13.2038 70.6332 13.8781 72.9036 14.0914C73.8103 14.1829 74.5874 14.2972 75.2351 14.4343C75.8903 14.5714 76.5874 14.8076 77.3265 15.1429C78.0655 15.4705 78.6331 15.9695 79.0293 16.64C79.4331 17.3029 79.6351 18.1143 79.6351 19.0743C79.6122 20.5753 78.9874 21.7867 77.7608 22.7086C76.5417 23.6305 74.9151 24.0914 72.8808 24.0914C69.7874 24.0914 67.376 23.1162 65.6465 21.1657L67.3722 18.8229C68.7512 20.3467 70.6065 21.1276 72.9379 21.1657C73.837 21.1657 74.6217 20.9943 75.2922 20.6514C75.9551 20.3086 76.2979 19.7943 76.3208 19.1086C76.3436 18.4229 76.0465 17.92 75.4293 17.6C74.8198 17.28 73.8941 17.0553 72.6522 16.9257C68.3627 16.5143 66.2065 14.9257 66.1836 12.16C66.1836 10.5524 66.8541 9.34859 68.1951 8.54859C69.536 7.74097 71.0636 7.33716 72.7779 7.33716C75.2693 7.33716 77.3074 8.04573 78.8922 9.46287L77.0293 11.6457Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.2686 3.09704C81.2686 3.95799 81.6114 4.5637 82.2971 4.91418C82.9828 5.26466 83.6686 5.26466 84.3543 4.91418C85.04 4.5637 85.3828 3.95799 85.3828 3.09704C85.3828 2.23608 85.04 1.63418 84.3543 1.29132C83.6686 0.948465 82.9828 0.948465 82.2971 1.29132C81.6114 1.63418 81.2686 2.23608 81.2686 3.09704ZM81.6571 7.69132V23.6113H84.9714V7.69132H81.6571Z" fill="white"/>
<path d="M91.1542 1.58862V7.79434H95.7828V10.6515H91.1199V18.2058C91.1199 19.9429 91.8819 20.8115 93.4057 20.8115C94.0228 20.8115 94.6971 20.6324 95.4285 20.2743L96.3885 23.0972C95.3219 23.5696 94.2628 23.8058 93.2114 23.8058C91.4742 23.8515 90.1409 23.3943 89.2114 22.4343C88.2742 21.482 87.8057 20.0724 87.8057 18.2058V1.94291L91.1542 1.58862Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M97.4521 3.09704C97.4521 3.95799 97.795 4.5637 98.4807 4.91418C99.1664 5.26466 99.8521 5.26466 100.538 4.91418C101.224 4.5637 101.566 3.95799 101.566 3.09704C101.566 2.23608 101.224 1.63418 100.538 1.29132C99.8521 0.948465 99.1664 0.948465 98.4807 1.29132C97.795 1.63418 97.4521 2.23608 97.4521 3.09704ZM97.8407 7.69132V23.6113H101.155V7.69132H97.8407Z" fill="white"/>
<path d="M119.806 7.72559L113.086 23.6799H109.452L102.697 7.72559H106.366L108.48 12.8456L111.28 20.2056L113.794 13.4856L116.172 7.72559H119.806Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M123.177 16.9257C123.33 18.1219 123.874 19.0857 124.812 19.8172C125.741 20.5486 126.922 20.9143 128.354 20.9143C129.215 20.9143 130.08 20.7581 130.949 20.4457C131.817 20.1333 132.488 19.7219 132.96 19.2114L135.109 21.3029C134.316 22.1562 133.303 22.8191 132.069 23.2914C130.842 23.7638 129.585 24 128.297 24C125.699 24 123.623 23.2381 122.069 21.7143C120.514 20.1905 119.737 18.1562 119.737 15.6114C119.737 13.1886 120.511 11.2038 122.057 9.65716C123.604 8.11049 125.608 7.33716 128.069 7.33716C130.728 7.33716 132.796 8.1524 134.274 9.78287C135.753 11.4133 136.343 13.7943 136.046 16.9257H123.177ZM132.857 14.1257C132.774 12.9067 132.305 11.9619 131.452 11.2914C130.606 10.6286 129.509 10.2972 128.16 10.2972C126.918 10.2972 125.852 10.6286 124.96 11.2914C124.069 11.9619 123.497 12.9067 123.246 14.1257H132.857Z" fill="white"/>
</svg>

  )

export default Apos