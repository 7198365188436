import React from "react"
import DeferitLogo from "../components/svg/deferit"
import WaddleLogo from "../components/svg/waddle"
import HalogenLogo from "../components/svg/halogen"
import AnuLogo from "../components/svg/anu"
import ImmutableLogo from "../components/svg/immutable"
import GbcaLogo from "../components/svg/gbca"
import AposLogo from "../components/svg/apos"
import JcLogo from "../components/svg/jc"
import SgLogo from "../components/svg/sg"
import EnLogo from "../components/svg/enabli"
import GsesLogo from "../images/gses-logo.png"
import ProasisLogo from "../images/proasis-logo.png"
import FixitLogo from "../components/svg/fixitdoc"
import PtLogo from "../components/svg/ptblink"
import GiraffeLogo from "../components/svg/giraffe"

const ClientLogos = () => (
    
    <>
            <div className="logo-client immu">
              <ImmutableLogo />
            </div>
            <div className="logo-client enabli">
              <EnLogo />
            </div>
            <div className="logo-client waddle">
              <WaddleLogo />
            </div>
            <div className="logo-client gbca">
              <GbcaLogo />
            </div>
            <div className="logo-client halogen">
              <HalogenLogo />
            </div>
            <div className="logo-client fixit">
              <FixitLogo />
            </div>
            <div className="logo-client giraffe">
              <GiraffeLogo />
            </div>
            <div className="logo-client def">
              <DeferitLogo />
            </div>
            <div className="logo-client pt">
              <PtLogo />
            </div>
            <div className="logo-client gses">
              <img src={GsesLogo} alt="GSES logo" />
            </div>
            <div className="logo-client jc">
              <JcLogo />
            </div>
            <div className="logo-client sg">
              <SgLogo />
            </div>
            <div className="logo-client apos">
              <AposLogo />
            </div>

            <div className="logo-client anu">
              <AnuLogo />
            </div>
            <div className="logo-client proasis">
              <img src={ProasisLogo} alt="Proasis logo" />
            </div>
    </>
)

export default ClientLogos