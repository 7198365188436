import React from "react"

const Deferit = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="126" height="28" fill="#FFF" viewBox="0 0 126 28">
    <g>
        <path fill="#FFF" fillRule="evenodd" d="M41.623 4.767c-1.257 1.168-1.885 2.878-1.885 5.128v.328h-2.49v3.407h2.49v12.973h3.538V13.63h3.867v-3.407h-3.867v-.328c0-2.402 1.288-3.484 3.867-3.242v-3.41c-2.424-.152-4.265.356-5.52 1.524zm-18.394 12.27c.239-1.246.795-2.228 1.67-2.949.873-.72 1.964-1.081 3.276-1.081 1.156 0 2.166.34 3.03 1.016.863.677 1.413 1.681 1.655 3.014H23.23zm4.979-7.24c-2.535 0-4.609.824-6.225 2.474-1.617 1.65-2.424 3.696-2.424 6.141 0 2.49.819 4.549 2.457 6.177 1.637 1.626 3.79 2.441 6.454 2.441 3.166 0 5.558-1.192 7.175-3.571l-2.916-1.705c-.941 1.355-2.349 2.031-4.226 2.031-1.356 0-2.501-.332-3.442-.998-.938-.666-1.54-1.622-1.8-2.866H36.3c.087-.612.131-1.103.131-1.476 0-2.402-.77-4.444-2.31-6.126-1.541-1.682-3.511-2.522-5.914-2.522zm42.326 3.177v-2.75h-3.538v16.38h3.538v-8.257c0-1.68.503-2.888 1.507-3.62 1.004-.732 2.185-1.054 3.538-.965V9.928c-2.468 0-4.15 1.016-5.044 3.046zm-19.197 4.063c.24-1.246.796-2.228 1.67-2.949.874-.72 1.965-1.081 3.276-1.081 1.157 0 2.168.34 3.03 1.016.864.677 1.414 1.681 1.656 3.014h-9.632zm4.98-7.24c-2.535 0-4.609.824-6.225 2.474-1.617 1.65-2.424 3.696-2.424 6.141 0 2.49.819 4.549 2.456 6.177 1.638 1.626 3.79 2.441 6.455 2.441 3.166 0 5.558-1.192 7.175-3.571l-2.916-1.705c-.941 1.355-2.35 2.031-4.226 2.031-1.356 0-2.501-.332-3.442-.998-.938-.666-1.54-1.622-1.8-2.866h13.039c.086-.612.131-1.103.131-1.476 0-2.402-.77-4.444-2.31-6.126-1.541-1.682-3.511-2.522-5.914-2.522zM12.415 22.165c-.983.993-2.217 1.489-3.702 1.489-1.485 0-2.72-.496-3.702-1.489-.984-.994-1.474-2.244-1.474-3.752 0-1.507.49-2.757 1.474-3.751.983-.993 2.217-1.491 3.702-1.491 1.485 0 2.72.498 3.702 1.49.984.995 1.474 2.245 1.474 3.752 0 1.508-.49 2.758-1.474 3.752zm1.474-9.616c-1.333-1.835-3.242-2.752-5.734-2.752-2.248 0-4.172.83-5.765 2.49C.796 13.946 0 15.987 0 18.412c0 2.402.796 4.44 2.39 6.11 1.594 1.671 3.518 2.507 5.766 2.507 2.492 0 4.401-.929 5.734-2.785v2.358h3.538V3.67H13.89v8.879z" clipRule="evenodd"/>
    </g>
    <path fill="#FFF" fillRule="evenodd" d="M125.823.022c-.053-.019-.11-.027-.175-.018-7.334 1.34-14.878 1.367-22.179.073-.457-.114-.917.147-1.041.595-.151.877-.247 1.764-.282 2.655 11.128.56 20.117-2.123 21.289-2.456-7.781 3.025-16.275 4.706-24.99 4.752-.781-.003-1.326.58-1.075 1.175.48 1.118 1.094 2.189 1.838 3.19.249.328.52.642.804.946.525.56 1.123 1.06 1.758 1.524-.766.674-1.68 1.236-2.377 1.612-.214.115-.105.441.135.414 10.318-1.178 20.229-7.567 26.386-14.046.157-.164.081-.348-.083-.413l-.008-.003z" clipRule="evenodd"/>
    <path fill="#FFF" fillRule="evenodd" d="M103.465.077c-.456-.114-.917.147-1.04.595-.152.877-.248 1.764-.283 2.655 11.128.56 20.118-2.123 21.289-2.456 0 0 .668-.6-.109-.488-6.596.952-13.324.852-19.857-.306zM94.972 11.55v-1.326h-4.061V5.637l-3.538.022v2.212l-.003.424c0 .522-.191.973-.573 1.355-.383.383-.842.574-1.38.574h-1.059v2.853c3.8.591 7.989.396 10.614-1.527M92.728 23.442c-.6-.033-1.052-.198-1.36-.49-.304-.296-.457-.783-.457-1.459v-5.796c-1.15.026-2.33.017-3.538-.027v5.823c0 2.14.589 3.61 1.769 4.405 1.18.798 3.123 1.033 5.83.706v-3.178c-.894.043-1.643.049-2.244.016M78.527 26.603h3.538V15.251c-1.157-.138-2.334-.307-3.538-.516v11.868zM81.883 11.865c.449-.448.672-.977.672-1.59 0-.612-.218-1.141-.654-1.59-.437-.447-.971-.671-1.605-.671s-1.169.224-1.607.672c-.437.448-.655.977-.655 1.59 0 .612.224 1.141.673 1.589.448.446.977.67 1.589.67.61 0 1.14-.224 1.587-.67z" clipRule="evenodd"/>
    </svg>
)

export default Deferit